import styled from "styled-components"
import { PoppinsText } from "../../utils/basic_component"


const BulletComponent = styled.div`
    display: flex;
`

const IconWrapper = styled.div`
    width: 6vw;

    @media only screen and (max-width: 414px) {
        width: 10vw;
    }
`

const TextRapper = styled.div`
    width: 22vw;
    display: flex;

    @media only screen and (max-width: 414px) {
        width: 40vw;
    }
`

const Center = styled.div`
    margin: auto 0vw auto 2.5vw;
`

interface bullet {
    children: (string | JSX.Element) [] | string
    icon_url: string
}


const Bullet = ({children, icon_url} : bullet) : JSX.Element => {
    return (<>
        <BulletComponent>
            <IconWrapper>
                <img src ={icon_url} alt ="neo life icon" />
            </IconWrapper>
            
            <TextRapper>
                <Center>
                    <PoppinsText fontsize={1} color="#AFB2C3" fontsizeMobile={2.7} lineHeightMobil={3.7} >
                        {children}
                    </PoppinsText>
                </Center>
            </TextRapper>
            

        </BulletComponent>
    
    </>)
}

export default Bullet