import styled from "styled-components"
import { PoppinsText } from "../../utils/basic_component"
import { useState } from "react"


const TabComponent = styled.div`
    margin-bottom: 2vw;
    width: 5vw;
    height: 1.3vw;

    @media only screen and (max-width: 414px) {
        width: 10vw;
        margin-bottom: 5vw;
    }
`

interface tab {
    children: string
    url: string
}
const Tab = ({children, url} : tab) : JSX.Element => {

    const [ hover, setHover] = useState<boolean>(false)
    return(<>
        <TabComponent onMouseLeave={ () => setHover(false)} onMouseOver = { () => setHover(true)}>
            <a href = {url}>
                <PoppinsText fontsize={ hover ? 0.9 : 0.8} color="white" fontsizeMobile={2.5} lineHeightMobil={4}>
                    {children}
                </PoppinsText>
            </a>
        </TabComponent>
    </>)
}

export default Tab