export const basketballEvent = [
    {
        src: "./fourth_section/galeria/1.jpg"
    },
    {
        src: "./fourth_section/galeria/2.jpg"
    },
    {
        src: "./fourth_section/galeria/3.jpg"
    },
    {
        src: "./fourth_section/galeria/4.jpg"
    },
    {
        src: "./fourth_section/galeria/5.jpg"
    },
    {
        src: "./fourth_section/galeria/6.jpg"
    },
    {
        src: "./fourth_section/galeria/7.jpg"
    },
    {
        src: "./fourth_section/galeria/8.jpg"
    },
    {
        src: "./fourth_section/galeria/9.jpg"
    },
    {
        src: "./fourth_section/galeria/10.jpg"
    },
    {
        src: "./fourth_section/galeria/11.jpg"
    },
    {
        src: "./fourth_section/galeria/12.jpg"
    },
    {
        src: "./fourth_section/galeria/13.jpg"
    },
    {
        src: "./fourth_section/galeria/14.jpg"
    },

]