import { BulletSection, ImageSection } from "./complex"
import styled from "styled-components"

const SexthSectionComponent = styled.section`
    display: flex;
    width: 100vw;
    height: 45vw;
    overflow: hidden;
    margin-top: 5vw;

    @media only screen and (max-width: 414px) {
        height: 135vw;
    }
`

const SexthSection = () : JSX.Element =>{

    return(<>
        <SexthSectionComponent>
            <BulletSection/>
            <ImageSection/>
        </SexthSectionComponent>
        
    </>)
}

export default SexthSection