import styled from "styled-components"


const LogoComponent = styled.div`
    width: 10vw;
    margin-top: 8vw;

    @media only screen and (max-width: 414px) {
        width: 20vw;
        margin-top: 12vw;
    }
`
const Logo = (): JSX.Element => {
    return (<>
        <LogoComponent>
            <img src ="./footer/logo.webp" alt = "logo" />
        </LogoComponent>
    </>)
}

export default Logo