import styled from "styled-components"
import { MenuButton } from "../basic_components"


const NavbarContainer = styled.nav`
    display: flex;
    padding: 2vw 7vw;
    
    @media only screen and (max-width: 414px) {
        padding: 5vw 7vw;
    }
`

const ButtonsWrapper = styled.div`
    margin-left: auto;
    display: flex;
`

const Navbar = () => {
    return(<>
        <NavbarContainer>

            <ButtonsWrapper>
                <a href="https://app.neolifeinvestments.com">
                    <MenuButton action={() => null}/>
                </a>
                
            </ButtonsWrapper>

        </NavbarContainer>
            
    </>)
}

export default Navbar