import styled from "styled-components"
import { useClickOutside, useBlockScreen } from "../../hooks"
import { useRef } from "react"
import { SimpleSlider } from "../../utils/basic_component"
import { basketballEvent } from "../../../settings"

const ShadowWrapper = styled.div`
    position: fixed;
    background-color: rgb(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    top: 0vw;
    z-index: 5;
    display: flex;
`

const Gallery = styled.div`
    width: 40vw;
    margin: auto;

    @media only screen and (max-width: 414px) {
        width: 90vw;
    }

`


const Margin = styled.div`
    display: flex;
    width: 100%;
    height: 40vw;
    padding: 0vw 0.2vw;
    img{
        margin: auto;
    }

    @media only screen and (max-width: 414px) {
        padding: 0vw 2vw;
        height: 90vw;
    }
    

`

interface OpenImageGallery{
    closeAction: () => void
    imgNumber: number
}

const OpenImageGallery = ({closeAction, imgNumber}: OpenImageGallery): JSX.Element =>{
    const gallery = useRef<HTMLDivElement | null>(null)

    useClickOutside(gallery,() => closeAction())
    useBlockScreen()


    return(<>
        <ShadowWrapper>
            <Gallery ref ={gallery}>
                <SimpleSlider imageNumber={imgNumber}>
                    {basketballEvent.map( el => {
                        return (
                                <div>    
                                    <Margin>
                                        <img src={el.src} alt="galleria neo life"/>
                                    </Margin>
                                </div>
                        )
                    })}
                </SimpleSlider>
            </Gallery>
        </ShadowWrapper>
    </>)
}

export default OpenImageGallery