
import FirstSection from "./components/firstSection/firstSection";
import SecondSection from "./components/secondSection/secondSection";
import ThirdSection from "./components/thirdSection/thirdSection";
import FourthSection from "./components/fourthSection/fourthSection";
import FifthSection from "./components/fifthSection/fifthSection";
import SexthSection from "./components/sexthSection/sexthSection";
import Footer from "./components/footer/footer";

const App = () =>{
  return (
          <>
            <FirstSection/>
            <SecondSection/>
            <ThirdSection/>
            <FifthSection/>
            <FourthSection/>
            <SexthSection/>
            <Footer/>
          </>
  );
}

export default App;
