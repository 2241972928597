import styled from "styled-components"
import { Navbar, TextBanner } from "./components"
import { Logo } from "./basic_components"

const FirstSectionComponent = styled.section`
    width: 100%;
    height: 65vw;
    position: flex;

    @media only screen and (max-width: 414px) {
        height: 130vw;
        overflow: hidden;
    }
`

const BannerNeoLife = styled.div`
    width: inherit;
    position: absolute;
    top: -0.1vw;
    z-index: -1;

    img{
        object-fit: cover;
    }
    

    @media only screen and (max-width: 414px) {
        
        img{
            object-fit: cover;
            height: 150vw;
        }
    }
`

const LogoPosition = styled.div`
    position: absolute;
    top: 7vw;
    left: 7vw;
`

const TextBannerPosition = styled.div`
    position: absolute;
    top:18vw;
    left: 36vw;

    @media only screen and (max-width: 414px) {
        top: 35vw;
        left: 28vw;
    }
`

const FirstSection = () => {
    return(<>
        <FirstSectionComponent>
            <Navbar/>
            <BannerNeoLife>
                <img src = "./first_section/banner/banner_neolife.webp" alt="banner neoLife "/>
            </BannerNeoLife>

            <LogoPosition>
                <Logo/>
            </LogoPosition>
           
            <TextBannerPosition>
                <TextBanner/>
            </TextBannerPosition>
           
        </FirstSectionComponent>
    </>)
}

export default FirstSection