import styled from "styled-components"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";

const BranchSection = styled.div`
    width: 100vw;
    position: relative;
`

const BranchImageWrapper = styled.div`
    position: absolute;
    width: 55vw;
    height: 14vw;
    left: -5vw;
    top: -5vw;

    @media only screen and (max-width: 414px) {
        width: 70vw;
        height: 14vw;
        left: -5vw;
        top: 5vw;
    }
`

const Branch = () =>{

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])

    return (<>
    
            <BranchSection data-aos="fade-right">
                <BranchImageWrapper>
                    <img src="./third_section/Rama-38.webp" alt="neo life investment"/>
                </BranchImageWrapper>
            </BranchSection>
    </>)
}

export default Branch