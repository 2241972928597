import { useState } from "react"
import styled from "styled-components"
import { PoppinsText } from "../../utils/basic_component"


const SocialMediaTabComponent = styled.div`
    
    height: 2vw;
    margin-bottom: 1vw;

    @media only screen and (max-width: 414px) {
        height: 5vw;
        margin-bottom: 3vw;
    }
`

const Link = styled.a`
    display: flex;
`

interface IconWrapper {
    hover: boolean
}
const IconWrapper = styled.div<IconWrapper>`
    width: ${props => props.hover ? "2.1" : "2"}vw;

    @media only screen and (max-width: 414px) {
        width: 5vw;
    }
`
const TextWrapper = styled.div`
    margin: auto 0vw auto 1vw;
`

interface socialMedia {
    url: string
    icon_url : string
    children: string
}

const SocialMediaTab = ({ url, icon_url, children } : socialMedia ) : JSX.Element => {

    const [ hover, setHover] = useState<boolean>(false)


    return(<>
            <SocialMediaTabComponent onMouseOver = {() => setHover(true)} onMouseLeave = {() => setHover(false)}>
                <Link href={url} target="_blank">
                <IconWrapper hover ={hover}>
                    <img src = {icon_url} alt ="social media icon neo life investment" />
                </IconWrapper>

                <TextWrapper>
                    <PoppinsText fontsize={hover ? 0.9 : 0.8} fontsizeMobile={2.5} lineHeightMobil = {4} color="white">{children}</PoppinsText>
                </TextWrapper>
                </Link>
            </SocialMediaTabComponent>
    </>)
}

export default SocialMediaTab