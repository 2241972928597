import styled from "styled-components"
import { PoppinsText } from "../../utils/basic_component"

const Flex = styled.div`
    display: flex;
`
const Italic = styled.a`
    color: #1F6257;
    font-style: italic;
`

const Weight = styled.a`
    font-weight: 200;
`

const TextBanner = () =>{
    return(<>
                <PoppinsText 
                    fontsize={2.2} 
                    color="#54555A"
                    fontsizeMobile={4}
                    fontweight={200}
                ><Weight>Life is richness, <Italic>forever.</Italic></Weight> </PoppinsText> 
     
    </>)
}

export default TextBanner