import styled from "styled-components"
import React from 'react'

const PoppinsTextStyle = styled.p<poppinsText>`
    font-family: Poppins;
    font-weight: ${props => props.fontweight ? props.fontweight : "400"};
    font-style: ${props => props.fontStyle ? props.fontStyle : "normal"};
    font-size: ${props => props.fontsize}vw;
    color ${props => props.color};
    text-align: ${props => props.textAlign != undefined ? props.textAlign : "left" };
    line-height:  ${props => props.lineHeight ? props.lineHeight : props.fontsize + 0.2}vw;
    &:first-letter{
        text-transform: uppercase; 
    }
    @media only screen and (max-width: 414px) {
        font-size: ${props => props.fontsizeMobile}vw;
        text-align: ${props => props.textAlignMobile};
        line-height:  ${props => props.lineHeightMobil ? props.lineHeightMobil  + 0.2 : ""}vw;
        color: ${props => props.colorMobile ? props.colorMobile : props.color }
    }
`

interface poppinsText{
    children: any,
    fontsize: number,
    fontsizeMobile?: number,
    textAlignMobile?: "center" | "right" | "left",
    textAlign?: "center" | "right" | "left",
    color: string
    lineHeight?: number
    lineHeightMobil?: number
    fontStyle?: "italic" 
    colorMobile?: string
    fontweight?: number
}

const PoppinsText = ({children, fontsize, color, textAlign, fontsizeMobile, textAlignMobile, lineHeightMobil, fontStyle, lineHeight, colorMobile, fontweight}: poppinsText) =>{
    
    return  <PoppinsTextStyle 
                fontsize={fontsize} 
                color={color}  
                textAlign={textAlign}
                fontsizeMobile={fontsizeMobile}
                textAlignMobile={textAlignMobile}
                lineHeightMobil={lineHeightMobil}
                fontStyle={fontStyle}
                lineHeight={lineHeight}
                colorMobile={colorMobile}
                fontweight={fontweight}
            >{children}</PoppinsTextStyle>
        
}

export default PoppinsText