import styled from "styled-components"
import { TextSection, VideoSection, Leaves } from "./complex"


const FifthSectionComponent = styled.section`
    display: flex;
    height: 50vw;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: 414px) {
        display: block;
        width: 100%;
        height: 180vw;
    }
`

const LeavesPosition = styled.div`
    position: absolute;
    top: 30vw;
`

const FifthSection = (): JSX.Element =>{
    return (<>
        <FifthSectionComponent>

            <VideoSection/>


            <TextSection/>  


            <LeavesPosition>
                <Leaves/>
            </LeavesPosition>
            
            
        </FifthSectionComponent>
        
    </>)
}

export default FifthSection