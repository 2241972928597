import styled from "styled-components"
import { Logo, SocialMedia, TabsSection, Right } from "./components"


const FooterComponent = styled.footer`
    width: 100vw;
    height: 40vw;
    background-color: #1F6257;
    padding: 0vw 10vw;

    @media only screen and (max-width: 414px) {
        height: 70vw;
    }
    
`
const Flex = styled.div`
    display: flex;
`
const Footer = (): JSX.Element => {

    return (<>
        <FooterComponent>
            <Flex>
                <Logo/>   
                <SocialMedia/>
            </Flex>

            <Right/>
        </FooterComponent>


    
    </>)
}

export default Footer