import styled from "styled-components"
import { PoppinsText } from "../../utils/basic_component"

const RightComponent = styled.div`
    height:15vw;
    display: flex;

    @media only screen and (max-width: 414px) {
        height:25vw;
    }
`

const ContentWrapper = styled.div`
    margin: auto auto 1vw auto;
`

const TextWrapper = styled.div`
  
`

const DotWrapper = styled.div`
    display: flex;
    margin-bottom: 1vw;
    margin-left: 2vw;

    @media only screen and (max-width: 414px) {
        margin-bottom: 3vw;
        margin-left: 3vw;
    }
`

const DotOne = styled.div`
    width: 0.5vw;
    height: 0.5vw;
    background-color: #CCCCCB;
    border-radius: 2vw;
    margin: auto 0.9vw;

    @media only screen and (max-width: 414px) {
        width: 2vw;
        height: 2vw;
        margin: auto 3vw;
    }
`

const DotTwo = styled.div`
    width: 0.4vw;
    height: 0.4vw;
    background-color: #CCCCCB;
    border-radius: 2vw;
    margin: auto 0.9vw;

    @media only screen and (max-width: 414px) {
        width: 1.5vw;
        height: 1.5vw;
        margin: auto 3vw;
    }
`

const DotThree = styled.div`
    width: 0.3vw;
    height: 0.3vw;
    background-color: #CCCCCB;
    border-radius: 2vw;
    margin: auto 0.9vw;

    @media only screen and (max-width: 414px) {
        width: 1vw;
        height: 1vw;
        margin: auto 3vw;
    }
`

const DotFour = styled.div`
    width: 0.2vw;
    height: 0.2vw;
    background-color: #CCCCCB;
    border-radius: 2vw;
    margin: auto 0.9vw;

    @media only screen and (max-width: 414px) {
        width: 0.5vw;
        height: 0.5vw;
        margin: auto 3vw;
    }
`

const Right = () : JSX.Element =>{
    return(<>
        <RightComponent>
            <ContentWrapper>

                <DotWrapper>
                        <DotOne/>
                        <DotTwo/>
                        <DotThree/>
                        <DotFour/>
                </DotWrapper>

                <TextWrapper>
                    <PoppinsText fontsize={0.5} fontsizeMobile = {1.5}  color="#CCCCCB">Todos los derechos reservados. NeoLife © 2022.</PoppinsText>
                </TextWrapper>

            </ContentWrapper>
        </RightComponent>
        
    </>)
}

export default Right