import { SocialMediaTab } from "./"
import styled from "styled-components"

const SocialMediaComponent = styled.div`
    margin-top: 8vw;
    margin-left: 10vw; 

    @media only screen and (max-width: 414px) {
        margin-top: 12vw;
        margin-left: 5vw; 
    }
`

const SocialMedia = () : JSX.Element => {
    return (<>
        <SocialMediaComponent>
            <SocialMediaTab icon_url="./footer/whatsApp.webp" url="https://wa.me/5212217181765?text=Hola!%20me%20gustaría%20obtener%20más%20información,%20por%20favor.🌱">WhapsApp</SocialMediaTab>
            <SocialMediaTab icon_url="./footer/twitter.webp" url="/">Twitter</SocialMediaTab>
            <SocialMediaTab icon_url="./footer/instagram.webp" url="https://instagram.com/neolifeinvestments">Instagram</SocialMediaTab>
        </SocialMediaComponent>
   
    </>)
}

export default SocialMedia