import styled from "styled-components"

const LogoWrapper = styled.div`
    width: 21vw;

    @media only screen and (max-width: 414px) {
        width: 35vw;
    }
`

const Logo = () =>{
    return(<>
        <LogoWrapper>
            <img src ="./first_section/logo/logo.webp" alt ="logo neo Life" />
        </LogoWrapper>
    
    </>)
}

export default Logo