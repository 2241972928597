import styled from "styled-components"
import "aos/dist/aos.css";
import React from "react";
import { Leaves, PlantImages, TextPart } from "./components";

const SecondSectionWrapper = styled.section`
  position: relative;
  padding: 0vw 5vw;
  height: 60vw;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 414px) {
    padding: 0vw 15vw;
    height: 210vw;
    overflow: hidden;
  }
`

const Flex = styled.div`
  display: flex;

  @media only screen and (max-width: 414px) {
    display: block;
  }
`

const LeavesPosition = styled.div`
  position: absolute;
  top 35vw;

  @media only screen and (max-width: 414px) {
    top 150vw;
  }
`

const SecondSection = () => {

    return(<>
      <SecondSectionWrapper >
        <Flex>
          <PlantImages/>
          <TextPart />
        </Flex>
        <LeavesPosition>
          <Leaves/>
        </LeavesPosition>
        
        
      </SecondSectionWrapper>
  
    </>)
}

export default SecondSection