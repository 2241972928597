import styled from "styled-components"

const ImageSectionComponent = styled.div`
    width: 100%;
`
const ImageWrapper = styled.div`

`

const ImageSection = () =>{
    return(<>
            <ImageSectionComponent>
                <ImageWrapper>
                    <img src = "./third_section/image_section_3.webp" alt ="neo life inversment"/>
                </ImageWrapper>
            </ImageSectionComponent>
    </>)
}

export default ImageSection