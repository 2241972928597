import styled from "styled-components"
import { PoppinsTitle, PoppinsText } from "."

const ParagraphDiv = styled.div`
    width: 45vw;

    @media only screen and (max-width: 414px) {
        width: 100%;
    }
`

const TitleWrapper = styled.div`
    margin-bottom: 4vw;

    @media only screen and (max-width: 414px) {
        margin-bottom: 8vw;
    }

`

const TextWrapper = styled.div`

`

interface Paragraph {
    colorTitle: string
    colorText: string
    title: string
    children: string
}

const Paragraph = ({title, colorTitle, colorText, children} : Paragraph ) => {
    return (<>
        <ParagraphDiv>

            <TitleWrapper>
                <PoppinsTitle fontsize={4} color={colorTitle} colorMobile ="#1F6257" fontsizeMobile={8} lineHeightMobil={8.5}>{title}</PoppinsTitle>
            </TitleWrapper>
            
            <TextWrapper>
                <PoppinsText fontsize={1.3} color = {colorText} lineHeight={2} colorMobile = "#AFB2C3" lineHeightMobil={7} fontsizeMobile={3} >
                    {children}
                </PoppinsText>
            </TextWrapper>
    
            
        </ParagraphDiv>
    
    </>)
}

export default Paragraph