import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface SimpleSlider{
  children: JSX.Element [] | JSX.Element
  imageNumber?: number
}

const SimpleSlider = ({children, imageNumber}: SimpleSlider ): JSX.Element => {
 
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      initialSlide: imageNumber ? imageNumber : 0
    };

    return (
        <Slider {...settings}>{children}</Slider>
    );
}

export default SimpleSlider

