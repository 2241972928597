import { ImageSection, TextSection, Branch } from "./components"
import styled from "styled-components"

const ThirdSectionSectionComponent = styled.section`
    width: 100%;
    height: 70vw;

    @media only screen and (max-width: 414px) {
        height: 240vw;
        overflow: hidden;
    }
`

const Flex = styled.div`
    display: flex;
    padding: 0vw 0vw 0vw 5vw;
    flex-direction: row;

    @media only screen and (max-width: 414px) {
        flex-direction: column;
        padding: 0vw;
      }
`

const OrderOne = styled.div`
    order: 1;
    width: 50%;
    display: flex;
    @media only screen and (max-width: 414px) {
        order: 2;
        width: 100%;
    }
`

const OrderTwo = styled.div`
    order: 2;
    width: 50%;

    @media only screen and (max-width: 414px) {
        order: 1;
        margin-bottom: 15vw;
        width: 100%;
    }
`

const ThirdSection = () =>{

    

    return (<>
            <ThirdSectionSectionComponent >
                <Flex id ="Flex">
                    <OrderOne id ="OrderOne">  
                        <TextSection/>
                    </OrderOne>

                    <OrderTwo id = "OrderTwo">
                        <ImageSection/>
                    </OrderTwo>
            
                </Flex>

                <Branch/>
                
            </ThirdSectionSectionComponent>
      
    
    </>)
}

export default ThirdSection