import styled from "styled-components"
import { GalleryImage, OpenImageGallery } from "."

const GalleryComponent = styled.div`
    width: 100%;
    position: relative;
`

const Item1 = styled.div`
    position: absolute;
    width: 13vw;
    height: 13vw;
    top: 0vw;
    left: 3vw;
    cursor: pointer;
`

const Item2 = styled.div`
    position: absolute;
    width: 50vw;
    height: 22vw;
    top: 15vw;
    left: 3vw;
    cursor: pointer;
`

const Item3 = styled.div`
    position: absolute;
    width: 34.5vw;
    height: 13vw;
    top: 0vw;
    left: 18.5vw;
    cursor: pointer;
`

const Item4 = styled.div`
    position: absolute;
    width: 25vw;
    height: 25vw;
    top: 0vw;
    left: 55vw;
    cursor: pointer;
`

const Item5 = styled.div`
    position: absolute;
    width: 28vw;
    height: 9.5vw;
    top: 27.5vw;
    left: 55vw;
    cursor: pointer;
`

const Item6 = styled.div`
    position: absolute;
    width: 14vw;
    height: 14vw;
    top: 0vw;
    left: 82.5vw;
    cursor: pointer;
`

const Item7 = styled.div`
    position: absolute;
    width: 11vw;
    height: 21.5vw;
    top: 15.5vw;
    left: 85.5vw;
    cursor: pointer;
`

interface Gallery{
    images: image []
    openGallery: (number: number) => void
}

interface image{
    src: string
}
const Gallery = ({images, openGallery}: Gallery): JSX.Element =>{

    return(<>
            <GalleryComponent>

                {images.map((el, index) => {

                    if(index === 0)
                        return (
                            <Item1  key={el.src} onClick={() => openGallery(index)}>
                                <GalleryImage src={el.src}/>
                            </Item1>
                        )

                    else if(index === 1)
                        return (
                            <Item2  key={el.src} onClick={() => openGallery(index)}>
                                <GalleryImage src={el.src} />
                            </Item2>
                        )


                    else if(index === 2)
                        return (
                            <Item3  key={el.src} onClick={() => openGallery(index)}>
                                <GalleryImage src={el.src}/>
                            </Item3>
                        )

                    else if(index === 3)
                        return (
                            <Item4  key={el.src} onClick={() => openGallery(index)}>
                                <GalleryImage src={el.src}/>
                            </Item4>
                        )

                    else if(index === 4)
                        return (
                            <Item5  key={el.src} onClick={() => openGallery(index)}>
                                <GalleryImage src={el.src}/>
                            </Item5>
                        )

                    else if(index === 5)
                        return (
                            <Item6  key={el.src} onClick={() => openGallery(index)}>
                                <GalleryImage src={el.src}/>
                            </Item6>
                        )

                    else if(index === 6)
                        return (
                            <Item7  key={el.src} onClick={() => openGallery(index)}>
                                <GalleryImage src={el.src}/>
                            </Item7>
                        )
                })}
            </GalleryComponent>
    </>)
}

export default Gallery