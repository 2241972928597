import styled from "styled-components"
import { useState } from "react"

const VideoSectionComponent = styled.div`
    width: 50vw;
    padding: 5vw 6vw 5vw 4vw;
    @media only screen and (max-width: 414px) {
        width: 100%;
        padding: 5vw 0vw;
    }
`

const VideoWrapper = styled.div`
    width: 40vw;
    height: 25vw;
    display: flex;
    @media only screen and (max-width: 414px) {
        width: 100%;
        height: 50vw;
    }
    background-color: red;
    img{

    }
`

const Video = styled.video.attrs( props => ({...props}))`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const PreviewWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`

const Preview = styled.img.attrs( props => ({...props}))`
    width: 100%;
    height: 100%;
    object-fit: cover;

`

const PlayButton = styled.div`
    position: absolute;
    top: 7.5vw;
    left: 15vw;
    width: 10vw;
    height: 10vw;
    cursor: pointer;
    &:hover{
        width: 12vw;
        height: 12vw;
        top: 6.5vw;
        left: 14vw;

        @media only screen and (max-width: 414px) {
            width: 30vw;
            height: 30vw;
            left: 35vw;
            top: 10vw;
        }
    }

    @media only screen and (max-width: 414px) {
        width: 30vw;
        height: 30vw;
        left: 35vw;
        top: 10vw;
    }

`


const VideoSection = () : JSX.Element => {

    const [videoSelected, setVideoSelected] = useState<boolean>(false)

    return(<>
        <VideoSectionComponent>
            <VideoWrapper >

                {videoSelected
                        
                    ?
                        <Video controls autoPlay>
                            <source src="./fifth_section/video.mp4" type="video/mp4"/>
                            Tu explorador no soporta videos.
                        </Video>
                    
                    :
                        <PreviewWrapper>
                                <Preview src="./fifth_section/image.webp" alt="preview video neo life investments"/>
                                <PlayButton onClick={() => setVideoSelected(true)}>
                                    <img src="./fifth_section/play_botton.webp" alt="preview video neo life investments"/>
                                </PlayButton>
                        </PreviewWrapper>
                        
                
                }
               

    
                        {/* <VideoImg src={videoImg.src} alt ={videoImg.alt}/> */}
                        {/* <PlayIconImg src="./images/icons/play.webp" alt = "play icon plaza mercury oaxaca" onClick={() => setVideoSelected(!videoSelected)}  /> */}
                   
              
            </VideoWrapper>
        </VideoSectionComponent>

    </>)
}

export default VideoSection