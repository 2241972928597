import styled from "styled-components"
import { Paragraph } from "../../utils/basic_component"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";

const TextSectionComponent = styled.div`
    width: 50vw;
    height: 30vw;
    display: flex;

    @media only screen and (max-width: 414px) {
       margin: 0vw;
       width: 100%;
       padding: 10vw 15vw;
    }
`

const Center = styled.div`
    margin: auto;
`

const TextSection = (): JSX.Element =>{

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])


        return(<>
                <TextSectionComponent  data-aos="fade-up">
                    <Center>
                        <Paragraph title="Mentalidad disruptiva e innovadora. " colorTitle="#1F6257" colorText="#AFB2C3"  >
                        En NeoLife creemos que los activos más valiosos que tenemos son el tiempo y la libertad. 
                        Por eso es que promovemos diferentes tipos de actividades y eventos para compartir con los amigos y la familia.
                        </Paragraph> 
                    </Center>  
                </TextSectionComponent>

            </>)
                
}

export default TextSection