import styled from "styled-components"
import React from 'react'

const PoppinsTextStyle = styled.h1<poppinsText>`
    font-family: Poppins;
    font-weight: 500;
    font-size: ${props => props.fontsize}vw;
    color ${props => props.color};
    text-align: ${props => props.textAlign != undefined ? props.textAlign : "left" };
    line-height:  ${props => props.fontsize + 0.2}vw;
    &:first-letter{
        text-transform: uppercase; 
    }
    @media only screen and (max-width: 414px) {
        font-size: ${props => props.fontsizeMobile}vw;
        text-align: ${props => props.textAlignMobile};
        line-height:  ${props => props.lineHeightMobil ? props.lineHeightMobil  + 0.2 : ""}vw;
        color: ${props => props.colorMobile ? props.colorMobile : props.color }
    }
`

interface poppinsText{
    children: string | string [],
    fontsize: number,
    fontsizeMobile?: number,
    textAlignMobile?: "center" | "right" | "left",
    textAlign?: "center" | "right" | "left",
    color: string
    lineHeightMobil?: number
    colorMobile?: string
}

const PoppinsText = ({children, fontsize, color, textAlign, fontsizeMobile, textAlignMobile, lineHeightMobil, colorMobile}: poppinsText) =>{
    
    return  <PoppinsTextStyle 
                fontsize={fontsize} 
                color={color}  
                textAlign={textAlign}
                fontsizeMobile={fontsizeMobile}
                textAlignMobile={textAlignMobile}
                lineHeightMobil={lineHeightMobil}
                colorMobile={colorMobile}
            >{children}</PoppinsTextStyle>
        
}

export default PoppinsText