import styled from "styled-components"


const ImageWrapper = styled.div`
    width: inherit;
    height: inherit;

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`

interface GalleryImage{
    src: string
}

const GalleryImage  = ({src}: GalleryImage): JSX.Element =>{

    return (<>
        <ImageWrapper>
            <img src={src} alt =" neolife investment galeria"/>
        </ImageWrapper>
    </>)
}

export default GalleryImage