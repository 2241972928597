import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./fonts/Poppins-Light.ttf"
import "./fonts/Poppins-Regular.otf"
import "./fonts/Poppins-Black.ttf"
import "./fonts/Poppins-Italic.ttf"
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


