import styled from "styled-components"
import { Bullet } from "../basic_component"
import { PoppinsTitle, PoppinsText } from "../../utils/basic_component"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";

const Black = styled.a`
    color: #1F6257 !important;
`

const BulletSectionComponent = styled.div`
    width: 50%;
    padding: 1vw 5vw;

    @media only screen and (max-width: 414px) {
        width: 70%;
    }
`

const TitleWrapper = styled.div`
    margin-bottom: 1vw;

    @media only screen and (max-width: 414px) {
        margin-bottom: 5vw;
    }
`

const TextWrapper = styled.div`
    margin-bottom: 2vw;

    @media only screen and (max-width: 414px) {
        margin-bottom: 5vw;
    }
`

const BulletOne = styled.div`
    margin: 0vw 0vw 2vw 8vw;

    @media only screen and (max-width: 414px) {
        margin: 0vw 0vw 7vw 4vw;
    }
`

const BulletTwo = styled.div`
    margin: 0vw 0vw 2vw 5vw;

    @media only screen and (max-width: 414px) {
        margin: 0vw 0vw 7vw 4vw;
    }
`

const BulletThree = styled.div`
    margin: 0vw 0vw 2vw 5vw;

    @media only screen and (max-width: 414px) {
        margin: 0vw 0vw 7vw 4vw;
    }
`

const BulletFour = styled.div`
    margin: 0vw 0vw 0vw 8vw;

    @media only screen and (max-width: 414px) {
        margin: 0vw 0vw 7vw 4vw;
    }
`


const BulletSection = () : JSX.Element => {

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])

    return(<>
        <BulletSectionComponent data-aos="fade-up">
            <TitleWrapper>
                <PoppinsTitle fontsize={3.5} color="#1F6257" fontsizeMobile={6}>NeoLife APP</PoppinsTitle>
            </TitleWrapper>
            
            <TextWrapper>
                <PoppinsText color="#AFB2C3" fontsize={1} fontsizeMobile={2.7} lineHeightMobil = {3.7}> Nuestra plataforma te permite llevar un monitoreo constante de tus inversiones, y si tienes alguna duda también te permite agendar una llamada con tu asesor.</PoppinsText>
            </TextWrapper>
            
            <BulletOne>
                <Bullet icon_url="./sexth_section/security.webp"> Inicio de sesión con <Black>ID y contraseña personalizados.</Black></Bullet>
            </BulletOne>
           
            <BulletTwo>
                <Bullet icon_url="./sexth_section/information.webp">Todas la información de tus contratos e inversiones en un <Black>sólo lugar y en tiempo real.</Black></Bullet>
            </BulletTwo>
            
            <BulletThree>
                <Bullet icon_url="./sexth_section/administration.webp"><Black>Administra tu perfil e informacion personal</Black> de manera sencilla.</Bullet>
            </BulletThree>
           
            <BulletFour>
                <Bullet icon_url="./sexth_section/download.webp"><Black>Descarga tu contrato </Black> directo desde la plataforma.</Bullet>
            </BulletFour>
            
        </BulletSectionComponent>
        
    </>)
}

export default BulletSection