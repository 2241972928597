import styled from "styled-components"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";


const LeavesComponent = styled.div`
    position: relative;
    width: 100vw;

`

const LeaveOne = styled.div`
    width: 20vw;
    position: absolute;
    right: -2vw;

    @media only screen and (max-width: 414px) {
        width: 45vw;
    }
`

const LeaveTwo = styled.div`
    width: 20vw;
    position: absolute;
    right: 9vw;
    top: 8vw;

    @media only screen and (max-width: 414px) {
        width: 45vw;
        right: 22vw;
        top: 11vw;
    }

`

const LeaveThree = styled.div`
    width: 20vw;
    position: absolute;
    right: 18vw;
    top: 5vw;

    @media only screen and (max-width: 414px) {
        width: 45vw;
        right: 38vw;
        top: 8vw;
    }

`

const Leaves = () => {

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])

    return(<>
        <LeavesComponent data-aos="fade-left">
            <LeaveOne>
                <img src = "./second_section/leaf1.webp" alt = "hojas neo life investments" />
            </LeaveOne>
            <LeaveTwo>
                <img src = "./second_section/leaf2.webp" alt = "hojas neo life investments" />
            </LeaveTwo>
            <LeaveThree>
                <img src = "./second_section/leaf3.webp" alt = "hojas neo life investments" />
            </LeaveThree>
            
        </LeavesComponent>
    
    </>)
}

export default Leaves