import styled from "styled-components"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";


const LeavesComponent = styled.div`
    position: relative;
    width: 100vw;

`

const LeaveOne = styled.div`
    width: 20vw;
    position: absolute;
    right: -7vw;
    top: -5vw;
    transform: rotate(-50deg);

    @media only screen and (max-width: 414px) {
        width: 45vw;
        right: -20vw;
        top: 105vw;
    }
`



const LeaveThree = styled.div`
    width: 25vw;
    position: absolute;
    right: 0vw;
    top: 4vw;
    transform: rotate(-35deg);

    @media only screen and (max-width: 414px) {
        width: 45vw;
        right: 0vw;
        top: 125vw;
    }

`

const Leaves = () => {

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])

    return(<>
        <LeavesComponent data-aos="fade-left">
            <LeaveOne>
                <img src = "./second_section/leaf1.webp" alt = "hojas neo life investments" />
            </LeaveOne>

            <LeaveThree>
                <img src = "./second_section/leaf3.webp" alt = "hojas neo life investments" />
            </LeaveThree>
            
        </LeavesComponent>
    
    </>)
}

export default Leaves