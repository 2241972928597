import styled from "styled-components"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Gallery } from "./"
import { basketballEvent } from "../../../settings"

const BannerComponent = styled.div`
    width: 100%;
`

interface Banner {
    openGallery: (number: number) => void
}

const Banner = ({ openGallery }:Banner) => {

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])

    return (<>
        <BannerComponent  data-aos="fade-up">
            <Gallery images={basketballEvent} openGallery ={openGallery}/>
        </BannerComponent>
        
    </>)
}

export default Banner