import styled from "styled-components"
import { Paragraph } from "../../utils/basic_component"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";


const TextPartComponent = styled.div`
    width: 50%;

    @media only screen and (max-width: 414px) {
        width: 100%;
    }

`

const ImageWrapper = styled.div`
    width: 12vw; 
    margin: auto;
`

const Flex = styled.div`
    display: flex;
    margin-bottom: 3vw;
`



const TextPart = () => {

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])
      
    return (<>
                <TextPartComponent data-aos="fade-up" >
                    <Flex>
                        <ImageWrapper>
                            <img src ="./second_section/Dots-18.webp" alt ="dots Neo life" />
                        </ImageWrapper>
                    </Flex>


                
                    <Paragraph title = "Bienvenid@ a NeoLife Investments" colorText="white" colorTitle="white">
                    Somos una firma con 7 años de experiencia y un objetivo muy simple:
                     Ayudar a nuestros clientes a cumplir sus objetivos financieros a través de un servicio integral y presonalizado.
                    </Paragraph>
                </TextPartComponent>    
    </>)

}

export default TextPart