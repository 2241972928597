import styled from "styled-components"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";

const IconComponent = styled.div`
    width: 28vw;
`

const Icon = () =>{

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])

    return (<>
        <IconComponent data-aos="fade-down">
            <img src="./fourth_section/icon-26.webp" alt ="icono neo Life"/>
        </IconComponent>
    </>)
}

export default Icon