import styled from "styled-components"
import React from 'react'
import { useState } from "react"

const MenuButtonWrapper = styled.div`
    width: 10vw;
    cursor: pointer;

    @media only screen and (max-width: 414px) {
        width: 20vw;
    }

    img{
        width: 100%;
    }

`
interface menuButton {
    action: () => void
}

const MenuButton = ({action} : menuButton) =>{

    const [hover, setHover] = useState<boolean>(false)

    return(<>
        <MenuButtonWrapper onMouseOver={() => setHover(true)} onMouseLeave = {() => setHover(false)} onClick = { action }>
            <img src = {hover ? "./first_section/button/menu_hover.webp" : "./first_section/button/menu.webp"} alt ="menu icon neo life" />
        </MenuButtonWrapper>
    </>)
}

export default MenuButton