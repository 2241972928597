import styled from "styled-components"

 const PlantImagesComponent = styled.div`
    width: 50vw;
    position: relative;

    @media only screen and (max-width: 414px) {
        width: 100%;
    }
 `

 const ImagePlantWrapper = styled.div`
    width: 35vw;

    @media only screen and (max-width: 414px) {
        width: 100%;
    }
 `

 const ImageGif = styled.div`
    position: absolute;
    top: -5.7vw;
    left: 24.5vw;
    width: 20vw;

    @media only screen and (max-width: 414px) {
        top: -10vw;
        left: 50vw;
        width: 40vw;
    }
 `

const PlantsImages = () =>{

    return (<>

        <PlantImagesComponent>

            <ImagePlantWrapper>
                <img src="./second_section/plants.webp" alt =" plantas  neo Life"/>     
            </ImagePlantWrapper>
            
            <ImageGif>
                <img src="./second_section/Plant.gif" alt =" planta  neo Life"/>  
            </ImageGif>

        </PlantImagesComponent>

    
    </>)
}

export default PlantsImages