import styled from "styled-components"
import Aos from "aos"
import "aos/dist/aos.css";
import { useEffect } from "react";

const ImageSectionComponent = styled.div`
    width: 50vw;
    position: relative;

    @media only screen and (max-width: 414px) {
        width: 30vw;
    }
`


const BackgroundImage = styled.div`
    width: 35vw;
    position: absolute;
    right: 0vw;
    z-index: 1;

    @media only screen and (max-width: 414px) {
        width: 90vw;
        right: -55vw;
    }
`

const CellphoneWrapper = styled.div`
    z-index: 2;
    position: absolute;
    width: 23vw;
    top: -1vw;
    right: 20vw;

    @media only screen and (max-width: 414px) {
        width: 50vw;
        top: 10vw;
        right: -3vw;
    }
`

const ImageSection = () : JSX.Element => {

    useEffect(() => {
        Aos.init({duration: 2000})
      }, [])

    return(<>
        <ImageSectionComponent>
            <BackgroundImage data-aos="fade-left">
                <img src = "./sexth_section/background.webp" alt="background neo Life investing" />
            </BackgroundImage>

            <CellphoneWrapper>
                <img src = "./sexth_section/phone.gif" alt="cellphone neo Life investing" />
            </CellphoneWrapper>

        </ImageSectionComponent>
    
    </>)
}

export default ImageSection