import { Tabs } from "."
import styled from "styled-components"

const TabSectionComponent = styled.div`
    margin-top: 8vw;
    padding: 0vw 5vw;

    @media only screen and (max-width: 414px) {
        margin-top: 12vw;
        padding: 0vw 10vw;
    }
`

const TabsSection = () : JSX.Element =>{


    return(<>
        <TabSectionComponent>
            <Tabs url="/">Nosotros</Tabs>
            <Tabs url="/">inversiones</Tabs>
            <Tabs url="/">Eventos</Tabs>
            <Tabs url="/">Aplicación</Tabs>
            <Tabs url="/">Contacto</Tabs>
        </TabSectionComponent>
    </>)
}

export default TabsSection