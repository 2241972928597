import styled from "styled-components"
import { Banner, Icon, OpenImageGallery } from "./components"
import { ConditionalRender } from "../utils/basic_component"
import { useState } from "react"

const FourthSectionComponent = styled.section`
    position: relative;
    height: 37.5vw;
    overflow: hidden;
    width: 100%;
    margin: 5vw 0vw;

    @media only screen and (max-width: 414px) {
        margin: 15vw 0vw;
    }
`

const IconPosition = styled.div`
    position: absolute;
    top: 3.3vw;
    left: 35vw;
`


const FourthSection = () => {

    const [ openGallery, setOpenGallery ] = useState<boolean>(false)
    const [ startImage, setStartImage ] = useState<number>(0)

    const openGalleryFunction = (imageNumber: number) =>{
        setOpenGallery(true)
        setStartImage(imageNumber)
    }

    return(<>
        <FourthSectionComponent>
            <Banner openGallery = {openGalleryFunction}/>

            <IconPosition>
                <Icon/> 
            </IconPosition>
              
        </FourthSectionComponent>

        <ConditionalRender condition={openGallery}>
            <OpenImageGallery closeAction = {() => setOpenGallery(false)} imgNumber={startImage}/>
        </ConditionalRender>
        
        
    </>)
}

export default FourthSection