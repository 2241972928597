import { Paragraph } from "../../utils/basic_component"
import styled from "styled-components"

const TextPartComponent = styled.div`
    width: 100%;
    margin: auto;    
    display: flex;

    @media only screen and (max-width: 414px) {
        padding: 0vw 15vw;
    }

`

const ImageWrapper = styled.div`
    width: 12vw; 
    margin: auto;

    @media only screen and (max-width: 414px) {
        width: 30vw; 
    }
`

const Flex = styled.div`
    display: flex;
    margin-top: 5vw;

    @media only screen and (max-width: 414px) {
        margin-top: 15vw; 
    }
`

const Margin = styled.div`
    margin: auto;
`
const TextSection = () =>{

    return(<>
             <TextPartComponent data-aos="fade-up" >

              
                <Margin>
                    <Paragraph title="El cambio es necesario." colorTitle="#1F6257" colorText="#AFB2C3">
                    La transición a una ecnomía digital es inminente pero no tienes de que preocuparte, en NeoLife te ofrecemos 
                    exposición a diversos activos digitales para que tú y tu familia puedan beneficiarse de esta nueva revolución.
                    </Paragraph>

                    <Flex>
                        <ImageWrapper>
                            <img src ="./second_section/Dots-23.webp" alt ="dots Neo life" />
                        </ImageWrapper>
                    </Flex>
                </Margin>
             </TextPartComponent>
    
      
    
    </>)
}

export default TextSection